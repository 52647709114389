import Swiper from 'swiper/swiper-bundle.min';
import anime from 'animejs';

export function index(){
	if(document.getElementById('indexSlide')){
		const swiper = new Swiper('#indexSlide',{
            autoplay: {
            	delay: 8000,
            	disableOnInteraction: true,
            },
			effect: 'fade',
            slidesPerView: 1,
            centeredSlides: false,
			speed: 1000,
            spaceBetween: 0,
            loop: true,
            simulateTouch: false,
			pagination: {
				el: '.swiper-pagination',
			},
			breakpoints: {
				768: {
					slidesPerView: 1,
					spaceBetween: 30,
					centeredSlides: false
				}
			},
		});
	}
    
    const fv = document.getElementById('firstview');
    if(fv){
        fv.classList.add('active');
    }
};