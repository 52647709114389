import anime from 'animejs';
import {index} from './index';

export function loading(){
    if(document.getElementById('intro')){

        const images = document.getElementsByTagName('img');
        const loading = document.getElementById('intro');
        const message = document.getElementById('message');
        let percent = 0;
        let finish = false;
        let leng = images.length;
        let imgCount = 0;
        let baseCount = 0;
        let current;
    
        function slicer(elem){
            const txtchange =  elem.innerHTML.replace(/<br>/g, '_');
            const txt =  txtchange;
            elem.innerHTML = '';
            txt.split('').forEach(function(c) {
                if(c == '_'){
                    elem.innerHTML += '<br>';
                }
                else if(c == ' '){
                    elem.innerHTML += '<span class="slice">&nbsp;</span>';
                }
                else{
                    elem.innerHTML += '<span class="slice">'+c+'</span>';
                }
            });
        }
        const slice_text = document.getElementsByClassName('txt_slice');
        for(let i = 0; i < slice_text.length; i++){
            slicer(slice_text[i]);
        }
        
        anime({
            targets: '#message .slice',
            keyframes: [
                { translateY : 0 },
                { translateY : -10 },
                { translateY : 0 },
            ],
            delay: anime.stagger(200),
            easing: 'easeInOutSine',
            loop: 10,
        });
		
		loading.classList.add('visible');

        for (let i = 0; i < images.length; i++) {
          let img = new Image();
          img.onload = function() {
              imgCount += 1;
          }
          img.onerror = function() {
              imgCount += 1;
          }
          img.src = images[i].src;
        };

        function disp(result){
            setTimeout(function(){
                let num = percent;
                if(result > num | num < 101){
                    percent = num +1;
                    disp(result);
                }else{
                    nowLoading();
                }

                if(num == 100){
                    if(!finish){
                        finish = true;
                        setTimeout(function(){
                            setTimeout(function(){
                                loading.classList.add('active');
                            },2400);
                            setTimeout(function(){
                                loading.style.display = 'none';
                                index();
                            },3200);
                        },800);
                    }
                }
            },8);
        }
        function nowLoading(){
          if(baseCount <= imgCount) {
              current = Math.floor(baseCount / leng * 100);
              disp(current);
              baseCount ++;
          }
        }

        if(leng == 0){
            loading.style.display = 'none';
        }else{
            nowLoading();
        }
    }
}